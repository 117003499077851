import * as Swal from "sweetalert2";

window.Main = new (function () {

    var that = this;

    /**
     * Init
     */
    this.init = function () {

        $('.in-gallery img').on('click', that.openGallery);

        $('#formConfirm button').on('click', that.validateForm);

        that.countDown();

        //

        $('.wrap-audio').on('click', that.playSoundTrack);
    };

    /**
     * Play Soundtrack
     */
    this.playSoundTrack = function () {

        if($(this).hasClass('playing')) {
            that.pauseSoundTrack();
            $(this).removeClass('playing');
            $(this).find('img').attr('src', '/images/img-play.webp');
        } else {
            $('audio').trigger("play");
            $(this).addClass('playing');
            $(this).find('img').attr('src', '/images/img-pause.webp');
        }
    };

    /**
     * Pause Soundtrack
     */
    this.pauseSoundTrack = function () {
        $('audio').trigger("pause");
    };

    /**
     * Open gallery
     */
    this.openGallery = function () {
        let goToSlider = $(this).data('img');

        Swal.fire({
            html: galleryContent,
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false
        });

        $('.wrap-arrows').removeClass('d-none');

        $('.slick-niria').slick({
            dots: false,
            prevArrow: $('.prev'),
            nextArrow: $('.next'),
            slidesToShow: 1,
            infinite: false,
        });

        $('.slick-niria').slick('slickGoTo', goToSlider - 1);

    };

    /**
     * Validate form
     */
    this.validateForm = function () {
        let theChoose = ($(this).hasClass('ok')) ? 'asistirá' : 'no asistirá';
        var validationPass = true;
        $('#confirm').val(theChoose);
        $("form :input").removeClass('error');

        $("form :input").each(function(){
            var input = $(this); // This is the jquery object of the input, do what you will
            var required = $(this).attr('required');

            if(typeof required !== 'undefined' && required !== false && $.trim($(this).val()) == '') {
                $(input).addClass('error');
                validationPass = false;
            }
        });

        if(validationPass) {
            $('#formConfirm').submit();
        }
    };

    /**
     * Count down
     */
    this.countDown = function () {
        var end = new Date('10/13/2023 02:30 PM');
        $('#countDown').countdown({
            date: end,
            offset: +2, // TODO Your Timezone Offset
            day: 'Día',
            days: 'Días',
            hideOnComplete: true
        }, function (container) {
            alert('Done!');
        });
        /*
        var _second = 1000;
        var _minute = _second * 60;
        var _hour = _minute * 60;
        var _day = _hour * 24;
        var timer;

        var now = new Date();
        var distance = end - now;

        var days = Math.floor(distance / _day);
        var hours = Math.floor((distance % _day) / _hour);
        var minutes = Math.floor((distance % _hour) / _minute);
        var seconds = Math.floor((distance % _minute) / _second);


        $('.wrap-count-down .wrap-days .days').html(days);
        $('.wrap-count-down .wrap-hours .hours').html(hours);
        $('.wrap-count-down .wrap-minutes .minutes').html(minutes);
        $('.wrap-count-down .wrap-seconds .seconds').html(seconds);

        setInterval(that.countDown, 1000);
         */
    };

});